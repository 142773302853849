.infoClass {
    color: black;
}

.errorClass {
    color: red;
}

.uploadBtn {
    margin-top: 1%;
}

.files {
    margin: 2%;
    padding:2%;
    border: 1px solid #dee2e6!important;
}